@import '../../base.scss';

.header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 5;
    position: absolute;
    top: 0px; 
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px 20px 0;
}

.info{
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.box_logo{
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
}

.contacts{
    max-width: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list{
    max-width: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item{
    box-sizing: content-box;
}

.link_telegram{
    width: 45px;
    height: 45px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(293.89deg, #0782BE 35.44%, #7BCEF8 94.34%);

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.link_whatsapp{
    width: 45px;
    height: 45px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(293.89deg, #008F00 35.44%, #0AC143 94.34%);

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.image_icon{
    width: 25px;
    height: 25px;
}

.box_phone{
    max-width: 230px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.phone{
    font-size: 25px;
    line-height: 1;
    font-weight: 700;
    margin: 0 0 5px;
    text-decoration: none;
    color: $element;
    vertical-align: bottom;

    &:hover{
        cursor: pointer;
        opacity: .5;
        transition: .3s;
    }
}


.nav{
    margin-top: 10px;
    max-width: 730px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


@media(max-width: 768px){

    .box{
        padding: 10px 10px;
        align-items: center;
    }

    .contacts{
        max-width: 300px;
        margin-left: 0px;
    }

    .box_phone{
        max-width: 200px;
    }

    .phone{
        font-size: 20px;
    }

    .list{
        max-width: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .item{
        box-sizing: content-box;
    }
    
    .link_telegram{
        width: 35px;
        height: 35px;
    }
    
    .link_whatsapp{
        width: 35px;
        height: 35px;
    }
    
    .image_icon{
        width: 20px;
        height: 20px;
    }

}

@media(max-width: 425px){

}