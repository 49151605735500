@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    padding-bottom: 30px
}

.title{
    @include title;
    margin-bottom: 30px;
}

.list{
    max-width: 1400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 5px;
    overflow: hidden;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: $background;
    overflow: hidden;
}

.box_image{
    width: 100%;
}

.image{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 8px;
}

.box_info{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.subtitle{
    font-size: 22px;
    line-height: 1.2;
    color: $white;
    margin: 0;
    text-align: center;
    max-width: 450px;
}

.text{
    font-size: 22px;
    line-height: 1.2;
    color: $element;
    font-weight: 700;
    margin: 5px 0 10px;
    text-align: center;
    max-width: 450px;
}

.button{
    color: $background;
    max-width: 100%;
    width: 100%;
    height: 50px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: $background-footer;
    background: linear-gradient(to left,#f6c478 0,#f6c150 50%,#f6c478 100%);
    border-radius: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    transform: translateY(-5px);
    box-shadow: 0 4px 0 #c49028;
    

    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 8px 4px rgba(#ffd57a, 0.25), 0px 0px 4px 4px rgba(#ffd57a, 0.25);
        transform: scale( .95 );
        transition: all .5s ease-out;
    }
}

@media(max-width: 1110px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}


@media(max-width: 768px){
    .subtitle{
        font-size: 24px;
    }

    
    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}