@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    @include box-column
}

.title{
    @include title;
    margin-bottom: 30px;
}

.line{
    background: $element;
    height: 2px;
    width: 33px;
    margin: 15px 0 10px; 
}

.subtitle{
    width: 100%;
    font-family:'Lovelace';
    font-weight: 400;
    font-size: 28px;
    line-height: 1.2;
    text-align: left;
    color: $title;
    margin: 0 0 20px;
}


.box_caput{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $box;
}


.box_title{
    font-family:'Lovelace';
    font-weight: 400;
    font-size: 19px;
    line-height: 1.2;
    text-align: left;
    color: $background;
    margin: 0;
    padding: 15px;
}

.list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: $white;
}

.item_grey{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: $box;
}

.item_box{
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text{
    font-weight: 500;
    font-size: 17px;
    line-height: 1;
    text-align: left;
    color: $text-box;
    margin: 0;
    padding: 15px;
}

.price{
    white-space: nowrap;
    padding: 15px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    text-align: left;
    color: $element;
    margin: 0;
}

.description{
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    padding: 0 15px 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    color: $text-box;
    margin: 0;
}

@media(max-width: 620px){
    .box_title{
        font-size: 16px;
    }

    .text{
        font-size: 14px;
    }

    .price{
        font-size: 16px;
    }

    .description{
        font-size: 12px;
    }
}
