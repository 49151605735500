@import '../../base.scss';

@keyframes pulse-1 {
    0%{
        transform: scale( .9);
        opacity: .9;
    }
    100%{
        transform: scale(1.2);
        opacity: .3;
    }
}

@keyframes pulse-2 {
    0%{
        transform: scale( .9);
        opacity: .9;
    }
    100%{
        transform: scale(1.4);
        opacity: .3;
    }
}

.button{
    width: 80px;
    height: 80px;
    background: linear-gradient(to left,#f6c478 0,#f6c150 50%,#f6c478 100%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 500;
    border: none;
    padding: 0;

    &:hover{
        cursor: pointer;
        transition: all .3s ease;
    }
}

.pulse{
    background: $element;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 50%;
    animation: pulse-1 1s infinite;
    z-index: -1;
}

.pulse_two{
    background: $element;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 50%;
    animation: pulse-2 1s infinite;
    z-index: -2;
}

.text{
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    margin: 0;
    text-decoration: none;
    text-align: center;
    color: $white;
}