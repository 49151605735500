@import '../../base.scss';


    .main {
        max-width: 100vw;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    .item {
        color: $white;
        background-image: url(../../image/InitialPage/img_2.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        background-attachment: fixed;
    } 

    .overlay{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(1, 15, 23, .6);
    }

.container{
    box-sizing: border-box;
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.title{
    font-family:  "Lovelace", "Arial ", sans-serif;
    max-width: 700px;
    font-size: 96px;
    line-height: 1.1;
    font-weight: 700;
    color: $title;
    text-align: center;
    margin: 0;
    position: relative;
    text-transform: uppercase;
}

.text{
    color: $title;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 500;
    text-align: center;
    margin: 20px 0 0 0;
}


.button{
    max-width: 300px;
    width: 100%;
    height: 50px;
    margin-top: 30px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: $background-footer;
    background: linear-gradient(to left,#f6c478 0,#f6c150 50%,#f6c478 100%);
    border-radius: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    transform: translateY(-5px);
    box-shadow: 0 4px 0 #c49028;

    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 8px 4px rgba(#ffd57a, 0.25), 0px 0px 4px 4px rgba(#ffd57a, 0.25);
        transform: scale( .95 );
        transition: all .5s ease-out;
    }
}


@media(max-width: 768px){

    .main {
        justify-content: center;
        align-items: center;
    }

    .title{
        font-size: 86px;
        max-width: 550px;
    }
}


@media(max-width: 450px){

    .title{
        font-size: 76px;
    }
}

@media(max-width: 425px){

    .title{
        max-width: 350px;
        font-size: 66px;
    }

    .text{
        max-width: 300px;
        font-size: 16px;
        text-align: center;
    }
}

@media(max-width: 380px){
    .title{
        max-width: 300px;
        font-size: 56px;
    }
}